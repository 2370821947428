/**
 * Returns true if the provided string is a valid email address
 * @param value string Email to validate
 */
const email = (value?: string): boolean => {
	if (!value || typeof value !== 'string') return false

	return !!value.match(/^\w[\w.-]*@([\w-]+\.)+[\w-]+$/)
}

const equal = (valueA?: string, valueB?: string): boolean => {
	return valueA === valueB
}

/**
 * Returns true if the provided value is a non-empty string
 * @param value string String to validate
 */
const nonEmptyString = (value?: string | null): boolean => {
	if (!value || typeof value !== 'string') return false

	return true
}

/**
 * Returns true if the provided string is a valid password
 * @param value string Password to validate
 */
const password = (value?: string): boolean => {
	if (!value || typeof value !== 'string') return false

	return value.length >= 6
}

export const validate = { email, equal, nonEmptyString, password }
